import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';

import { IonicModule } from '@ionic/angular';
import { LetDirective } from '@ngrx/component';
import { MarkdownModule } from 'ngx-markdown';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { TableModule } from 'primeng/table';
import { CalendarModule } from 'primeng/calendar';
import { AutoCompleteModule } from 'primeng/autocomplete';

import { LottieModule } from 'ngx-lottie';
import { MATOMO_CONFIGURATION } from '@codeculture/ngx-matomo';
import { MeaChatSconnectService } from 'mea-chat-sconnect-libs';


// Components
import { ContentCardComponent } from './components/content-card/content-card.component';
import { DownloadPreviewComponent } from './components/download-preview/download-preview.component';
import { EasterComponent } from './components/easter/easter.component';
import { ExpandableSidebarComponent } from './components/expandable-sidebar/expandable-sidebar.component';
import { FullPageLoaderComponent } from './components/full-page-loader/full-page-loader.component';
import { IconCircleComponent } from './components/icon-circle/icon-circle.component';
import { InfoModalComponent } from './components/modalComponents/info-modal/info-modal.component';
import { InterfererComponent } from './components/interferer/interferer.component';
import { TemplateModalComponent } from './components/modalComponents/template-modal/template-modal.component';
import { ListComponent } from './components/list/list.component';

import { TableOldComponent } from './components/table-old/table-old.component';
import { TableNoResultsComponent } from './components/table-no-results/table-no-results.component';
import { ModalComponent } from './components/modal/modal.component';
import { PopoverActionsComponent } from './components/popover-actions/popover-actions.component';
import { WidgetComponent } from './components/widget/widget.component';
import { WidgetSmallComponent } from './components/widget-small/widget-small.component';
import { PaginationDotsComponent } from './components/pagination-dots/pagination-dots.component';
import { PaginationBarComponent } from './components/pagination-bar/pagination-bar.component';
import { PdfViewerComponent } from './components/pdf-viewer/pdf-viewer.component';
import { DateRangePickerComponent } from './components/date-range-picker/date-range-picker.component';
import { StatisticsDonutChartComponent } from './components/statistics-donut-chart/statistics-donut-chart.component';
import { StatisticsBarChartComponent } from './components/statistics-bar-chart/statistics-bar-chart.component';
import { StatisticsBarChartTrendComponent } from './components/statistics-bar-chart-trend/statistics-bar-chart-trend.component';
import { StatisticsMiniBarChartComponent } from './components/statistics-mini-bar-chart/statistics-mini-bar-chart.component';
import { StatisticsSimpleBarChartComponent } from './components/statistics-simple-bar-chart/statistics-simple-bar-chart.component';
import { BadgeComponent } from './components/badge/badge.component';
import { AppointmentBadgeComponent } from './components/appointment-badge/appointment-badge.component';
import { SelectPopoverComponent } from './components/select-popover/select-popover.component';
import { AccordionComponent } from './components/accordion/accordion.component';
import { DateSelectionWidgetComponent } from './components/date-selection-widget/date-selection-widget.component';
import { SlideNavComponent } from './components/slide-nav/slide-nav.component';
import { TextInformationModalComponent } from './components/text-information-modal/text-information-modal.component';
import { StaticPageModalComponent } from './components/static-page-modal/static-page-modal.component';
import { RotateDeviceScreenComponent } from './components/rotate-device-screen/rotate-device-screen.component';
import { WaitingRoomComponent } from './components/waiting-room/waiting-room.component';
import { TabsWidgetComponent } from './components/tabs-widget/tabs-widget.component';
import { BarChartBaseComponent } from './components/bar-chart-base-component/bar-chart-base.component';
import { DropdownInputComponent } from './components/dropdown-input/dropdown-input.component';
import { DropdownListComponent } from './components/dropdown-list/dropdown-list.component';
import { SliderHorizontalComponent } from './components/slider-horizontal/slider-horizontal.component';
import { SegmentButtonComponent } from './components/segment-button/segment-button.component';
import { BulkActionModalComponent } from './components/bulk-action-modal/bulk-action-modal.component';
import { CardComponent } from './components/card/card.component';
import { TableWidgetComponent } from './components/table-widget/table-widget.component';
import { SurveyComponent } from './components/survey/survey.component';
import { SurveyModalComponent } from './components/survey-modal/survey-modal.component';
import { InputPopoverComponent } from './components/input-popover/input-popover.component';
import { TableComponent } from './components/table/table.component';
import { SearchModalComponent } from './components/table/search-modal/search-modal.component';
import { SidebarComponent } from './components/table/sidebar/sidebar.component';

// Services
import { ActionButtonService } from './services/actionButton.service';
import { ApiService } from './services/api.service';
import { AppointmentService } from './services/appointment.service';
import { BulkActionService } from './services/bulkAction.service';
import { DataChangedService } from './services/dataChanged.service';
import { DocumentService } from './services/document.service';
import { DynamicPageService } from './services/dynamicPage.service';
import { EducationService } from './services/education.service';
import { SubscriptionTriggerService } from './services/subscription-trigger.service';
import { UtilService } from './services/util.service';
import { InputValidationService } from './services/inputValidation.service';
import { ModalService } from './services/modal.service';
import { PopoverService } from './services/popover.service';
import { NotificationGoToEntryService } from './services/notification-go-to-entry.service';
import { NotificationService } from './services/notification.service';
import { NotificationSoundService } from './services/notification-sound.service';
import { NotificationReminderService } from './services/notificationReminder.service';
import { OrderService } from './services/order.service';
import { PdfService } from './services/pdf.service';
import { SafetyDatasheetsService } from './services/safety-datasheets.service';
import { SettingsService } from './services/settings.service';
import { StatisticsService } from './services/statistics/statistics.service';
import { VideoPlayerService } from './services/videoPlayer.service';
import { SconnectAuthService } from './services/authentication/sconnect.auth.service';
import { RouteLeaveDecoratorService } from './decorators/routeChange.decorator';


// Pipes
import { ArrayReducePipe } from './pipes/array-reduce.pipe';
import { ArraySplitPipe, ArraySplitOrdersPipe } from './pipes/array-split.pipe';
import { AuditLogLabelPipe } from './pipes/auditLog-label.pipe';
import { CommunicationZoneHasReferencePipe } from './pipes/communication-zone-types.pipe';
import { DatePickerToDatePipe } from './pipes/date-picker-to-date.pipe';

import { FilterExcludedPipe } from './pipes/filter-excluded.pipe';
import { FilterNumberArrayPipe } from './pipes/filter-number-array.pipe';
import { FilterResultValuePipe } from './pipes/filter-result.pipe';
import { FilterStringArrayPipe } from './pipes/filter-string-array.pipe';
import { FilterArrayPipe } from './pipes/filter-array.pipe';
import { FormatCurrencyPipe } from './pipes/format-currency.pipe';
import { FormInputValidationPipe, FormInputErrorMessagePipe } from './pipes/form-validation.pipe';
import {
    DateRangeToDate,
    DateRangeToLabel,
    DateTimeToDatePipe,
    DateTimeToTimePipe,
    FormatDateToCustomFormat,
    FormatDateInComparisonToTodayPipe,
    FormatDateTimeInComparisonToTodayPipe,
    FormatDateInComparisonToTimePipe,
    FormatDateTimeRange,
    FormatDateTimeToDateTimePipe,
    FormatDateTimeToMomentPipe,
    FormatDateToDayMonth,
    FormatDateToMonthYear,
    CompareDatesPipe,
    IsDateInListPipe,
    IsDateTimeAfter,
    IsDateSameOrBeforeToday
} from './pipes/date.pipe';
import { FormatPercentPipe } from './pipes/format-percent.pipe';
import { DocumentDownloadFilenamePipe } from './pipes/document-download-filename.pipe';
import {
    DocumentTypeFilterLabelPipe,
    DocumentNumberFormattedPipe,
    DocumentTypeNumberOfDocumentsPipe,
    DocumentSubTypeFilterLabelPipe,
    DocumentGetYearMonthPipe
} from './pipes/document-type.pipe';
import { FilterVisibleTableItemsPipe } from './pipes/filter-visible-table-items.pipe';

import { FilePreviewImagePipe } from './pipes/file-preview-image.pipe';
import { IsInArrayPipe, IsInArrayNotPurePipe, IncludesPipe, FindPipe } from './pipes/is-in-array.pipe';
import { OrderStatusFilterLabelPipe } from './pipes/order-status-filter-label.pipe';
import { OrderTypeFilterLabelPipe } from './pipes/order-type-filter-label.pipe';
import { GetOrderStatusDescriptionPipe } from './pipes/order-status-description.pipe';
import { GetImageUrlPipe } from './pipes/get-image-url.pipe';
import { GetUserAvatarPipe, GetMeaUserAvatarPipe, GetDefaultAvatarPipe } from './pipes/get-user-avatar.pipe';
import { GetValueByIdPipe, GetValueByParam } from './pipes/get-value-by-id.pipe';
import {
    InputValidationMaxLengthPipe,
    InputValidationMinLengthPipe,
    InputValidationRequiredPipe
} from './pipes/input-validation.pipe';
import { IsSettingReadOnlyPipe } from './pipes/is-setting-read-only.pipe';
import { LabelCustomerPositionSelectPipe } from './pipes/label-customer-position-select.pipe';
import { LabelPharmacyDataSelectPipe } from './pipes/label-pharmacy-data-select.pipe';
import { LabelNotificationReminderTypePipe } from './pipes/label-notification-reminder-type.pipe';
import { LinksTargetPipe } from './pipes/links-target.pipe';
import {
    NotificationButtonLabelByTypePipe,
    NotificationLabelByTypePipe
} from './pipes/notification-label-by-type.pipe';
import {
    NotificationHasEntryPipe,
    NotificationAdditionalActionLabelPipe,
    NotificationGoToEntryPermissionPipe
} from './pipes/notification-has-entry.pipe';
import { MediaToSrcsetPipe, MediaUrlByTypePipe } from './pipes/media.pipe';
import { OrderByPipe } from './pipes/order-by.pipe';
import { OrderQuantityDeliveredPipe } from './pipes/order-deliveries.pipe';
import { ReplacePayloadPipe } from './pipes/replace-payload.pipe';
import { SanitizeHtmlPipe } from './pipes/sanitize.pipe';
import { SliceTextPipe, ConvertLineBreakPipe, FormatModalMessage } from './pipes/slice-text.pipe';
import { StatisticTypeLabelPipe } from './pipes/statistic-type-label.pipe';
import { StatisticTypeNotePipe } from './pipes/statistic-type-note.pipe';
import { TagColorPipe } from './pipes/tag-color.pipe';
import { TextMinLengthPipe, TextMinTwoLinesPipe } from './pipes/text.pipe';
import { TranslatePipe } from './pipes/translate.pipe';
import {
    UserAdminGetPharmacyStoreNamePipe,
    UserAdminPharmacyOwnerAndCountPipe,
    UserAdminIsCurrentUserPipe,
    UserAdminCanActivatePipe,
    UserAccessRightTooltipPipe
} from './pipes/user-administration.pipe';
import { ValidateRegistrationsPipe } from './pipes/validate-registrations.pipe';
import { GetVaccineDosesPipe } from './pipes/offers.pipe';
import { DivideByPipe } from './pipes/math.pipe';
import { FindAttributePipe } from './pipes/find-attribute.pipe';
import { MasonryPipe } from './pipes/masonry.pipe';
import {
    StringToNumberPipe,
    LastPartOfStringPipe,
    StringToUppercasePipe,
    BoldPipe,
    SubstringPipe, RemoveInlineStyles
} from './pipes/string.pipe';

// Directives
import { ScrollDirective } from './directives/scroll-to-bottom.directive';
import { SwiperDirective } from './directives/swiper.directive';
import { TooltipDirective } from './directives/tooltip.directive';
import { TrimInputsDirective } from './directives/trim-inputs.directive';
import { ClickOutsideDirective } from './directives/click-outside.directive';

import { MarkdownConfig } from './config/markdown.config';
import { ScriptService } from './services/script.service';
import { FormModalComponent } from './components/form-modal/form-modal.component';
import { BulkTdComponent } from './components/table/bulk-td/bulk-td.component';
import { OrdersService } from '../pages/logistics/pages/orders/components/orders.service';
import { ReturnsService } from '../pages/logistics/pages/returns/components/returns.service';

import { BannerModule } from '../ui/banner/banner.module';

const moduleDeclarations =  [
    AccordionComponent,
    AppointmentBadgeComponent,
    BadgeComponent,
    BarChartBaseComponent,
    BulkActionModalComponent,
    CardComponent,
    ContentCardComponent,
    DateRangePickerComponent,
    DateSelectionWidgetComponent,
    DownloadPreviewComponent,
    DropdownInputComponent,
    DropdownListComponent,
    EasterComponent,
    ExpandableSidebarComponent,
    FullPageLoaderComponent,
    IconCircleComponent,
    InfoModalComponent,
    InterfererComponent,
    ListComponent,
    ModalComponent,
    PaginationBarComponent,
    PaginationDotsComponent,
    PdfViewerComponent,
    SurveyComponent,
    SurveyModalComponent,
    PopoverActionsComponent,
    RotateDeviceScreenComponent,
    SelectPopoverComponent,
    SlideNavComponent,
    StatisticsBarChartComponent,
    StatisticsBarChartTrendComponent,
    StatisticsMiniBarChartComponent,
    StatisticsSimpleBarChartComponent,
    StatisticsDonutChartComponent,
    TableComponent,
    BulkTdComponent,
    SearchModalComponent,
    SidebarComponent,
    TableOldComponent,
    TableWidgetComponent,
    TabsWidgetComponent,
    TableNoResultsComponent,
    TemplateModalComponent,
    TextInformationModalComponent,
    SegmentButtonComponent,
    FormModalComponent,
    SliderHorizontalComponent,
    StaticPageModalComponent,
    WidgetComponent,
    WidgetSmallComponent,
    WaitingRoomComponent,
    InputPopoverComponent,

    ArrayReducePipe,
    ArraySplitPipe,
    ArraySplitOrdersPipe,
    AuditLogLabelPipe,
    CommunicationZoneHasReferencePipe,
    CompareDatesPipe,
    ConvertLineBreakPipe,
    DatePickerToDatePipe,
    FormatModalMessage,
    DateRangeToDate,
    DateRangeToLabel,
    DateTimeToTimePipe,
    DateTimeToDatePipe,
    DocumentDownloadFilenamePipe,
    DocumentGetYearMonthPipe,
    DocumentNumberFormattedPipe,
    DocumentTypeNumberOfDocumentsPipe,
    DocumentTypeFilterLabelPipe,
    DocumentSubTypeFilterLabelPipe,
    FilterVisibleTableItemsPipe,
    FilePreviewImagePipe,
    IsInArrayPipe,
    IsInArrayNotPurePipe,
    FindPipe,
    IncludesPipe,
    OrderStatusFilterLabelPipe,
    OrderTypeFilterLabelPipe,
    FilterExcludedPipe,
    FilterNumberArrayPipe,
    FilterResultValuePipe,
    FilterStringArrayPipe,
    FilterArrayPipe,
    FindAttributePipe,
    FormatCurrencyPipe,
    FormatDateToCustomFormat,
    FormatDateInComparisonToTodayPipe,
    FormatDateTimeInComparisonToTodayPipe,
    FormatDateInComparisonToTimePipe,
    FormatDateTimeRange,
    FormatDateTimeToDateTimePipe,
    FormatDateTimeToMomentPipe,
    FormatDateToDayMonth,
    FormatDateToMonthYear,
    FormatPercentPipe,
    DivideByPipe,
    FormInputErrorMessagePipe,
    FormInputValidationPipe,
    GetOrderStatusDescriptionPipe,
    GetImageUrlPipe,
    GetUserAvatarPipe,
    GetMeaUserAvatarPipe,
    GetDefaultAvatarPipe,
    GetVaccineDosesPipe,
    GetValueByIdPipe,
    GetValueByParam,
    InputValidationMaxLengthPipe,
    InputValidationMinLengthPipe,
    InputValidationRequiredPipe,
    IsDateInListPipe,
    IsSettingReadOnlyPipe,
    LabelCustomerPositionSelectPipe,
    LabelPharmacyDataSelectPipe,
    LabelNotificationReminderTypePipe,
    LastPartOfStringPipe,
    LinksTargetPipe,
    MasonryPipe,
    NotificationAdditionalActionLabelPipe,
    NotificationButtonLabelByTypePipe,
    NotificationLabelByTypePipe,
    NotificationGoToEntryPermissionPipe,
    NotificationHasEntryPipe,
    MediaToSrcsetPipe,
    MediaUrlByTypePipe,
    OrderByPipe,
    OrderQuantityDeliveredPipe,
    ReplacePayloadPipe,
    SanitizeHtmlPipe,
    SliceTextPipe,
    StatisticTypeLabelPipe,
    StatisticTypeNotePipe,
    StringToNumberPipe,
    StringToUppercasePipe,
    BoldPipe,
    SubstringPipe,
    RemoveInlineStyles,
    TagColorPipe,
    TextMinLengthPipe,
    TextMinTwoLinesPipe,
    TranslatePipe,
    UserAccessRightTooltipPipe,
    UserAdminPharmacyOwnerAndCountPipe,
    UserAdminGetPharmacyStoreNamePipe,
    UserAdminIsCurrentUserPipe,
    UserAdminCanActivatePipe,
    ValidateRegistrationsPipe,

    ClickOutsideDirective,
    ScrollDirective,
    SwiperDirective,
    TooltipDirective,
    TrimInputsDirective,

];

// Exports are the same as declarations. Use push to add additional items
const moduleExports = moduleDeclarations;

/**
 * S-Connect Core Module
 *
 * This module provides common components and services. It also contains interfaces and the Apollo Graphql functionality.
 *
 */
@NgModule({
    declarations: [
        moduleDeclarations,
        IsDateTimeAfter,
        IsDateSameOrBeforeToday
    ],
    providers: [
        ActionButtonService,
        ApiService,
        AppointmentService,
        BulkActionService,
        DataChangedService,
        DocumentService,
        DynamicPageService,
        EducationService,
        InputValidationService,
        ModalService,
        NotificationGoToEntryService,
        NotificationService,
        NotificationSoundService,
        NotificationReminderService,
        OrderService,
        PdfService,
        PopoverService,
        SafetyDatasheetsService,
        ScriptService,
        SettingsService,
        StatisticsService,
        SubscriptionTriggerService,
        UtilService,
        VideoPlayerService,
        MeaChatSconnectService,

        // Component Services
        OrdersService,
        ReturnsService,

        SconnectAuthService,
        RouteLeaveDecoratorService,
        FormatCurrencyPipe,
        DocumentNumberFormattedPipe,
        FormatDateTimeToDateTimePipe,
        DateTimeToDatePipe,
        {
            provide: MATOMO_CONFIGURATION,
            useValue: {
                scriptUrl: '',
                requireConsent: false
            }
        }
    ],

    imports: [
        CommonModule,
        FormsModule,
        CalendarModule,
        AutoCompleteModule,
        TableModule,
        ReactiveFormsModule,
        IonicModule,
        HttpClientModule,
        NgxDaterangepickerMd.forRoot(),
        MarkdownModule.forRoot(MarkdownConfig),
        LottieModule.forRoot({player: playerFactory}),
        NgOptimizedImage,
        LetDirective,
        RouterLink,
        BannerModule
    ],
    exports: [
        moduleExports,
        IsDateTimeAfter,
        IsDateSameOrBeforeToday
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CoreModule {
    // Force creation of decorator services,
    // this is necessary to ensure the services are injectable!
    public constructor(routeLeaveDecoratorService: RouteLeaveDecoratorService) {

    }
}

export function playerFactory() {
    return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web');
}
