import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { NavigationRoutesEnum } from '../navigation/navigation.routes';

@Injectable(
    { providedIn: 'root' }
)
export class FeatureFlagGuard {
    constructor(private router: Router) {
    }

    assortmentFeatureFlag = environment.featureFlags.assortment;
    termsFeatureFlag = environment.featureFlags.terms;
    newsletterFeatureFlag = environment.featureFlags.newsletter;
    archiveFeatureFlag = environment.featureFlags.archive;
    canActivate(route: ActivatedRouteSnapshot): boolean {
        let hasAccess: boolean;
        switch (route.url[0].path) {
            case 'assortment':
                hasAccess = this.assortmentFeatureFlag;
                if (!hasAccess) {
                    localStorage.removeItem('active_tab_app_statistics');
                }
                break;
            case 'general-terms':
                hasAccess = this.termsFeatureFlag;
                break;
            case 'newsletter':
                hasAccess = this.newsletterFeatureFlag;
                break;
            case 'archive':
                hasAccess = this.archiveFeatureFlag;
                break;
            default:
                hasAccess = true;
        }

        if (!hasAccess) {
            void this.router.navigateByUrl(NavigationRoutesEnum.dashboard);
        }
        return hasAccess;
    }
}
