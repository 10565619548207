import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    computed,
    effect,
    EventEmitter,
    HostListener,
    inject,
    Input,
    Output,
    signal,
    Signal,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { Table } from 'primeng/table';
import { isEqual } from 'lodash';
import {
    TableColumnInterface,
    TableFilterConfigInterface,
    TableNoResultsInterface,
    TableSearchModalConfigInterface,
    TableSidebarInterface,
    TableSortInterface
} from '../../interfaces/table.interface';
import { DocumentFiltersInterface } from '../../interfaces/document.interface';
import { ListFiltersInterface } from '../../interfaces/filter-result.interface';
import { DateSelectionFilterInterface } from '../../interfaces/date-range.interface';
import { ReturnsFiltersInterface, ReturnsInterface } from '../../interfaces/returns.interface';
import { ExportConfig } from '../../config/export.config';
import { AdditionalDateRangeConfig, DateRangeConfig } from '../../config/date-range.config';
import { BadgeTypeEnum } from '../../enums/badge.enum';
import { TableTypeEnum } from '../../enums/table-type.enum';
import { DateRangeOptionCodes } from '../../enums/date-range.enum';
import { SortDirectionEnum } from '../../enums/sort-direction.enum';
import { TableFieldsEnum, TableFilterTypeEnum } from '../../enums/table.enum';
import { BulkActionCodeEnum, BulkActionTypeEnum } from '../../enums/bulk-action.enum';
import { formatDateToCustomFormat } from '../../formatting/date.formatting';
import { OrderBulkActionConfig } from '../../config/order-bulk-action.config';
import { BulkModeVar } from '../../store/locals/bulk-mode.var';
import { BulkActionModalComponent } from '../bulk-action-modal/bulk-action-modal.component';
import { ModalService } from '../../services/modal.service';
import { BulkActionService } from '../../services/bulkAction.service';
import { FilterResultValuePipe } from '../../pipes/filter-result.pipe';

@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
    providers: [FilterResultValuePipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent implements AfterViewInit {
    private modalService = inject(ModalService);
    private bulkModeVar = inject(BulkModeVar);
    private bulkActionService = inject(BulkActionService);
    private filterResultValuePipe = inject(FilterResultValuePipe);
    private cdr = inject(ChangeDetectorRef);

    protected readonly BulkActionCodeEnum = BulkActionCodeEnum;

    /**
     * Data to be displayed in the rows
     */
    @Input({required: true}) data: Signal<ReturnsInterface[]>;
    @Input({required: true}) isLoading: Signal<boolean>;
    /**
     * Columns to be displayed in the table
     */
    @Input({required: true}) columns: TableColumnInterface[];
    /**
     * A unique identifier for the table, this is used to scroll rows into view, when the selection of rows changes.
     */
    @Input({required: true}) tableId: string;
    @Input({required: true}) tableType: TableTypeEnum;
    /**
     * Configuration that determines the filters of the table.
     * This value is optional, when a headerTemplateRef is provided, else it is required to show filters.
     */
    @Input() filterConfig: TableFilterConfigInterface[];
    /**
     * Filters for the right side (above the sidebar)
     */
    @Input() filterRightConfig: TableFilterConfigInterface[];
    /**
     * The filters of the table showing the current values
     */
    @Input() filters: Signal<ReturnsFiltersInterface | DocumentFiltersInterface>;
    /**
     * The default filters are used to determine, if active filters are used.
     */
    @Input() defaultFilters: ReturnsFiltersInterface | DocumentFiltersInterface;
    /**
     * Defines the column and direction the table is sorted by
     */
    @Input() sort: Signal<TableSortInterface>;
    /**
     * Minimum date for date filters
     */
    @Input() minDate: Date | null;

    @Input() expandedRowKeys: Signal<{[key: string]: boolean}>;

    /**
     * Indicate if a search is in progress
     */
    @Input() searchInProgress: Signal<boolean>;

    /**
     * Hold the id of the selected row
     */
    @Input() selectedRowId: Signal<number>;
    /**
     * Function to update the selected row id in the respective service
     * @Input upDown: -1 for up, 1 for down
     */
    @Input() updateSelectedRowId: (upDown: number) => void;
    /**
     * Height of the row in pixels. This is used for virtual scrolling.
     */
    @Input() rowHeight = 50;
    /**
     * Defines the maximum number of rows that are displayed at once before lazy loading is triggered
     */
    @Input() numberOfRows = 100;


    /**
     * Defines if the table has bulk mode
     */
    @Input() bulkModeAvailable = false;
    /**
     * Defines the maximum number of rows that can be selected in bulk mode
     */
    @Input() bulkSelectionCountMax = 30;
    /**
     * Configuration for the bulk actions that can be performed on the selected rows
     */
    @Input() bulkActionConfig = OrderBulkActionConfig.filter(action => action.code !== BulkActionCodeEnum.DEFAULT);


    /**
     * Configuration for the search modal, that determines the filters to be displayed in the modal
     */
    @Input() searchModalConfig: TableSearchModalConfigInterface;
    /**
     * Error message returned from the full text search query for documents
     */
    @Input() statusErrorMessage: string;
    /**
     * Data that is displayed in the sidebar. It shows the data of the currently selected row.
     */
    @Input() sidebarData: Signal<TableSidebarInterface>;
    /**
     * Template defining the filters in the header of the table
     */
    @Input() filterTemplateRef?: TemplateRef<any>;
    /**
     * Reference to the template to be used for the header
     */
    @Input() headerTemplateRef?: TemplateRef<any>;
    /**
     * Reference to the template to be used for the table body
     */
    @Input({required: true}) bodyTemplateRef?: TemplateRef<any>;
    /**
     * Reference to the template to be used for the row expansion
     */
    @Input() rowExpansionTemplateRef?: TemplateRef<any>;
    /**
     * Popover that displays different action items the user can perform on the selected row
     */
    @Input() actionsPopoverRef?: TemplateRef<any>;
    /**
     * Template reference to the respective details modal component
     */
    @Input() detailsModalRef?: TemplateRef<any>;
    /**
     * Emits an event, when the offset changed. This is used for lazy loading.
     */
    @Output() offsetChange = new EventEmitter<number>();
    /**
     * Emits an event, when a new row is selected.
     */
    @Output() rowSelected = new EventEmitter<number>();
    /**
     * Emits an event, when the filters of the table changes
     */
    @Output() filtersChange = new EventEmitter<ListFiltersInterface>();


    isSearchModalOpen = false;

    bulkActionLabel = OrderBulkActionConfig.find(action => action.code === BulkActionCodeEnum.DEFAULT).label;
    bulkActionDefaultLabel = this.bulkActionLabel;
    bulkActionBadgeType = BadgeTypeEnum.ORDER_BULK_ACTION;

    bulkModeActive = this.bulkModeVar.isActive;
    bulkSelection = this.bulkModeVar.selectedItems;
    bulkSelectAction = this.bulkModeVar.selectedAction;

    dateFilter = computed<DateSelectionFilterInterface>(() => {
        const filters = this.filters();

        // check if filters is DocumentFiltersInterface
        if ('recDateOption' in filters) {
            return {
                label: 'Datum',
                selectedValue: filters?.recDateOption,
                selectedValues: {
                    dateOption: filters?.recDateOption,
                    dateRangeOptions: DateRangeConfig,
                    dateFrom: filters?.recDateFrom,
                    dateTo: filters?.recDateTo,
                },
            };
        }


        // check if filters is ReturnFiltersInterface
        if ('dateOption' in filters) {
            return {
                label: 'Datum',
                selectedValue: filters?.dateOption,
                selectedValues: {
                    dateOption: filters?.dateOption,
                    dateRangeOptions: DateRangeConfig,
                    dateFrom: filters?.dateFrom,
                    dateTo: filters?.dateTo,
                }
            };
        }
    });

    filterActive = computed<boolean>(() => {
        return !isEqual(this.defaultFilters, this.filters());
    });

    searchFieldText = computed<string>(() => {
        const filters = this.filters();
        const searchArray = [];
        if(this.searchModalConfig) {
            for (const item of this.searchModalConfig.items) {
                if (this.filterConfig.findIndex(it => it.dataKey !== 'search' && it.dataKey === item.dataKey) > -1) {
                    // do not show filters that are already displayed in the header except for the search term
                    continue;
                }
                if (item.type === TableFilterTypeEnum.date) {
                    if (filters[item.dataKey + 'Option'] === DateRangeOptionCodes.individual) {
                        const dateFrom = formatDateToCustomFormat(filters[item.dataKey + 'From']);
                        const dateTo = formatDateToCustomFormat(filters[item.dataKey + 'To']);
                        searchArray.push(`${item.label}: ${dateFrom} - ${dateTo}`);
                    } else if (filters[item.dataKey + 'Option'] !== DateRangeOptionCodes.all) {
                        const valueTranslation = item.selectableValues.find(it => it.id === filters[item.dataKey + 'Option'])?.title || '';
                        searchArray.push(`${item.label}: ${valueTranslation}`);
                    }
                }
                if (filters[item.dataKey] && filters[item.dataKey] !== 'all' && filters[item.dataKey] !== 'Alle') {
                    if(item.type === TableFilterTypeEnum.search) {
                        searchArray.push(`${filters[item.dataKey]}`);
                    } else {
                        searchArray.push(`${item.label}: ${filters[item.dataKey]}`);
                    }
                }
            }
        }

        return searchArray.join(', ');
    });

    /**
     * Array of filters and their labels/values
     */
    noResultFilters = computed<TableNoResultsInterface[]>(() => {
        const filters = this.filters();
        const noResultFilters: TableNoResultsInterface[] = [];

        const checkFilters = (item) => {
            if(noResultFilters?.findIndex(it => it.dataKey === item.dataKey) !== -1
            || !(item?.label && item?.dataKey)) {
                return;
            }

            if(item?.type === TableFilterTypeEnum.trueFalse) {
                noResultFilters.push({
                    label: item.label || '',
                    value: filters[item.dataKey] ? 'Ja' : 'Nein',
                    type: item.type,
                    dataKey: item.dataKey,
                    onAction: item.onAction
                });
            }

            if (filters[item.dataKey]
            && (typeof filters[item.dataKey] === 'string' && filters[item.dataKey].toLowerCase() !== 'all' && filters[item.dataKey] !== 'Alle')
            && item?.type !== TableFilterTypeEnum.trueFalse){
                noResultFilters.push({
                    label: item.label || '',
                    value: item.type === TableFilterTypeEnum.multiSelect
                        ? this.filterResultValuePipe.transform(item, filters[item.dataKey])
                        : item.selectableValues?.find(it => it.id === filters[item.dataKey])?.title || filters[item.dataKey],
                    type: item.type,
                    dataKey: item.dataKey,
                    onAction: item.onAction
                });
            }

            if (item?.type === TableFilterTypeEnum.date && filters[item.dataKey + 'Option']) {
                if(filters[item.dataKey + 'Option'] === DateRangeOptionCodes.individual) {
                    const dateFrom = filters[item.dataKey + 'From'];
                    const dateTo = filters[item.dataKey + 'To'];
                    noResultFilters.push({
                        label: item.label || '',
                        value: `${formatDateToCustomFormat(dateFrom)} - ${formatDateToCustomFormat(dateTo)}`,
                        type: item.type,
                        dataKey: item.dataKey,
                        onAction: item.onAction
                    });
                }

                if (filters[item.dataKey + 'Option'] !== DateRangeOptionCodes.individual
                && filters[item.dataKey + 'Option'] !== DateRangeOptionCodes.all) {
                    let dateConfig = DateRangeConfig.find(conf => conf.id === filters[item.dataKey + 'Option']);
                    if (!dateConfig) {
                        dateConfig = AdditionalDateRangeConfig[filters[item.dataKey + 'Option']];
                    }
                    noResultFilters.push({
                        label: item.label || '',
                        value: dateConfig.title,
                        type: item.type,
                        dataKey: item.dataKey,
                        onAction: item.onAction
                    });
                }
            }

            if (noResultFilters?.findIndex(it => it.label === item.label) === -1
                && item?.type !== TableFilterTypeEnum.trueFalse
                && (typeof filters[item.dataKey] === 'string'
                    && filters[item.dataKey] && filters[item.dataKey].toLowerCase() !== 'all'
                    && filters[item.dataKey] !== 'Alle'
                )
            ) {
                noResultFilters.push({
                    label: item.label || '',
                    value: filters[item.dataKey],
                    type: item.type,
                    dataKey: item.dataKey,
                    onAction: item.onAction
                });
            }
        };

        if (this.searchModalConfig) {
             this.searchModalConfig.items.forEach(item => {
                 checkFilters(item);
            });
        }
        if (this.filterConfig) {
            this.filterConfig.map(item => {
                checkFilters(item);
            });
        }
        return noResultFilters;
    });

    isSidebarOpen = signal(true);

    filterTypes = TableFilterTypeEnum;
    sortEnum = SortDirectionEnum;
    filterBadgeType = BadgeTypeEnum.FILTER_RESULT;
    exportConfig = ExportConfig.excel;
    filtersBeforeBulk = null;
    first = 0;

    @ViewChild('table') newTable: Table;

    @HostListener('document:keydown.ArrowUp', ['$event']) onArrowUpHandler(event: KeyboardEvent) {
        if (event?.srcElement['tagName'] === 'TEXTAREA' || event?.srcElement['tagName'] === 'INPUT') {
            return;
        }
        event.preventDefault();
        this.onRowSelect(-1);
    }

    @HostListener('document:keydown.ArrowDown', ['$event']) onArrowDownHandler(event: KeyboardEvent) {
        if (event?.srcElement['tagName'] === 'TEXTAREA' || event?.srcElement['tagName'] === 'INPUT') {
            return;
        }
        event.preventDefault();
        this.onRowSelect(1);
    }

    constructor() {
        effect(() => {
            // Reset the offset when the filters or sort changes
            // Sort: Otherwise if the user is on the last page and changes the sorting the last page of the table will be empty
            if(this.filters() && this.sort()) {
                this.first = 0;
                this.offsetChange.emit(0);
            }
        }, {allowSignalWrites:true});

        effect(() => {
            this.isSidebarOpen.set(!!this.sidebarData() && !(this.searchInProgress && this.searchInProgress()));
        }, {allowSignalWrites:true});

        effect(() => {
            if(this.expandedRowKeys && this.expandedRowKeys() && this.newTable) {
                const rowKeys = this.expandedRowKeys();
                // tslint:disable-next-line:forin
                for(const key in rowKeys) {
                    if(rowKeys[key]) {
                        this.newTable.expandedRowKeys[key] = true;
                    } else {
                        delete this.newTable.expandedRowKeys[key];
                    }
                }
            }
        });
    }

    ngAfterViewInit() {
        /**
         * When the page is reloaded and the user changes the browser tab, the table body will not be rendered (reason could not be determined).
         * This hook ensures that the change detection of the table is triggered correctly when the user changes back to Sconnect and the table
         * body is rendered.
         */
        document.addEventListener('visibilitychange', () => {
            if (document.visibilityState === 'visible') {
                setTimeout(() => {
                    this.newTable.cd?.detectChanges();
                }, 1000);
            }
        });
    }

    onRowSelect(upDown: number) {
        const selectedRowIndex = this.data().findIndex(item => item.id === this.selectedRowId());
        const newSelectedId = this.data()[selectedRowIndex + upDown]?.id || this.selectedRowId();
        if (newSelectedId !== this.selectedRowId()) {
            this.rowSelected.emit(newSelectedId);
        }
    }

    /**
     * When the search modal closes the search terms of the input select components need to be reset to the value of the according filter.
     */
    searchModalWillDismiss() {
        this.searchModalConfig.items.forEach(filter => {
            if (filter.type === TableFilterTypeEnum.inputSelect) {
                const value = this.filters()[filter.dataKey];
                filter.setSecondarySignal(value);
            }
        });
        this.isSearchModalOpen = false;
    }

    /**
     * User en-/disables bulk mode
     * @param isEnabled - Indicator if bulk mode should be enabled or disabled
     */
    changeBulkActionMode(isEnabled: boolean)  {
        this.filtersBeforeBulk = this.bulkModeVar.setActive(isEnabled, this.filters());
        if(!isEnabled) {
            this.filtersChange.emit(this.filtersBeforeBulk);
            this.bulkActionLabel = OrderBulkActionConfig.find(action => action.code === BulkActionCodeEnum.DEFAULT).label;
        }
    }

    /**
     * User changes the action of the bulk operation
     * @param action - Bulk action
     */
    bulkActionSelected(action: BulkActionCodeEnum) {
        this.bulkModeVar.setSelectedAction(action);
        this.bulkActionLabel = OrderBulkActionConfig.find(item => item.code === action).label;

        const bulkActionFilters = this.bulkActionService.getBulkActionFilters(action, this.tableType, this.filtersBeforeBulk);
        const filters = {...this.filtersBeforeBulk, ...bulkActionFilters};
        this.filtersChange.emit(filters);
    }

    /**
     * User submits current action and selection
     */
    async submitBulkAction() {
        let bulkType = null;
        if(this.tableType === TableTypeEnum.Order || this.tableType === TableTypeEnum.OrderBulk) {
            bulkType = BulkActionTypeEnum.ORDERS;
        }

        if(!bulkType) {
            return;
        }

        // Filter current list of orders for selected items
        const items = this.data().filter(item => this.bulkSelection().includes(item.id));

        const modal = await this.modalService.create(
            BulkActionModalComponent,
            {
                bulkType,
                items,
                bulkAction: this.bulkSelectAction()
            }
        );
        modal.onDidDismiss().then(() => {
           this.changeBulkActionMode(false);
        });
        await this.modalService.present(modal);
    }

    onSortChange(field: TableFieldsEnum) {
        if (this.sort) {
            this.sort().onUpdate(field);
        }
    }

    onCloseSidebar() {
        this.isSidebarOpen.set(false);
    }

    rowTrackBy(item: any): number {
        return item.id;
    }

    trackBy(index: number, item: any): number {
        return index;
    }
    clearFilter() {
        this.searchModalConfig.onReset();
    }
    triggerSearchModal(event: any, isOpen: boolean) {
        if (!event.target.className.includes('clickable')) {
            this.isSearchModalOpen = isOpen;
        }
    }

    next() {
        this.first = this.first + this.numberOfRows;
    }

    prev() {
        this.first = this.first - this.numberOfRows;
    }

    reset() {
        this.first = 0;
    }

    pageChange(event) {
        this.first = event.first;
        this.numberOfRows = event.rows;
        const offset = event.first >= 0 ? event.first : 0;
        this.offsetChange.emit(offset);

        // We have to trigger the change detection manually, because the table does not update the rows correctly (list stays empty)
        // In most cases 500ms is enough, but sometimes it needs a bit longer so we trigger it again after 1000ms.
        setTimeout(() => {
            this.cdr.detectChanges();
        }, 500);

        setTimeout(() => {
            this.cdr.detectChanges();
        }, 1000);
    }
}
