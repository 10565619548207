import { Component, inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { animate, state, style, transition, trigger } from '@angular/animations';

// Core Files
import { ContactPersonService, InputValidationService, ModalService } from '../../../../../../core/core.services';
import {
    CommunicationZoneFormEnum,
    CommunicationZoneReferenceTypeEnum,
    InputValidationFieldEnum,
    matomoIdsEnum,
    ModalClassEnum
} from '../../../../../../core/core.enums';
import { CommunicationZoneFormsConfig } from '../../../../../../core/config/communication-zone-forms.config';
import { CommunicationZoneMutations } from '../../../../../../core/store/graphql/mutations/communication-zone.graphql';
import {  TOOLTIPS } from '../../../../../../core/core.config';
import {
    CommunicationZoneFormsSubstituteInterface
} from '../../../../../../core/interfaces/communication-zone.interface';


@Component({
    selector: 'app-communication-zone-form',
    templateUrl: './communication-zone-form.component.html',
    styleUrls: ['./communication-zone-form.component.scss'],
    animations: [
        trigger('suggestionsVisible', [
            state('visible', style({
                flex: '1 1 32%',
                width: '*',
                padding: '*',
                opacity: 1
            })),
            state('hidden', style({
                flex: '0 0 0',
                padding: 0,
                width: 0,
                opacity: 0
            })),
            transition('visible=>hidden', animate('250ms')),
            transition('hidden=>visible', animate('250ms')),
        ])
    ]
})
export class CommunicationZoneFormComponent implements OnInit {
    static modalClass = ModalClassEnum.large;

    private communicationZoneMutation = inject(CommunicationZoneMutations);
    private contactPersonService = inject(ContactPersonService);
    private validationService = inject(InputValidationService);
    private modalService = inject(ModalService);
    private formBuilder = inject(FormBuilder);

    @Input() id = null;
    @Input() type : CommunicationZoneFormEnum = CommunicationZoneFormEnum.default;
    @Input() payload: CommunicationZoneFormsSubstituteInterface = null;
    @Input() replaceArray: Array<{key: string, value: string}> = null;
    @Input() isReadOnly: boolean = null;

    referenceType: CommunicationZoneReferenceTypeEnum;

    matomoId = matomoIdsEnum.contactSupport;

    tooltips = TOOLTIPS;

    validators = this.validationService.communicationZoneValidators;
    contentPrefix = '';
    validationFieldsEnum = InputValidationFieldEnum;
    title = '';
    contactNames: Array<string>;

    validationFormGroup: FormGroup;

    ngOnInit() {
        const formGroup = {
            title: [],
            content: ['', this.validationService.getFormValidators(InputValidationFieldEnum.content, this.validators)],
            contactPerson: ['', this.validationService.getFormValidators(InputValidationFieldEnum.contactPerson, this.validators)]
        };

        this.validationFormGroup = this.formBuilder.group(formGroup);

        switch (this.type) {
            case CommunicationZoneFormEnum.order:
                this.referenceType = CommunicationZoneReferenceTypeEnum.ORDERS;
                break;
            case CommunicationZoneFormEnum.returns:
                this.referenceType = CommunicationZoneReferenceTypeEnum.RETURNS;
                break;
            case CommunicationZoneFormEnum.documentLS:
                this.referenceType = CommunicationZoneReferenceTypeEnum.LS_DOCUMENT;
                break;
            case CommunicationZoneFormEnum.documentCH:
                this.referenceType = CommunicationZoneReferenceTypeEnum.CH_DOCUMENT;
                break;
            case CommunicationZoneFormEnum.documentESR:
            case CommunicationZoneFormEnum.documentETR:
                this.referenceType = CommunicationZoneReferenceTypeEnum.RE_DOCUMENT;
                break;
        }
        this.checkPredefinedForm();

        this.contactNames = this.contactPersonService.get();
    }

    /**
     * Check if there is a predefined form value
     */
    checkPredefinedForm() {
        if (this.type) {
            // Use JSON parse to avoid reference to the original object
            const config = CommunicationZoneFormsConfig
                .find(item => item.formId === this.type);
            if (!config) {
                return;
            }
            const predefinedFormValues = JSON.parse(JSON.stringify(config));

            if (this.replaceArray) {
                this.replaceArray.forEach(replaceItem => {
                    predefinedFormValues.values.content = predefinedFormValues.values.content.replace(replaceItem.key, replaceItem.value);
                    predefinedFormValues.values.title = predefinedFormValues.values.title.replace(replaceItem.key, replaceItem.value);
                });
            }

            if (this.isReadOnly) {
                this.validationFormGroup.patchValue({
                        title: predefinedFormValues.values.title,
                        contactPerson: predefinedFormValues.values.contactPerson
                    });
                this.title = predefinedFormValues.values.title;
                this.contentPrefix = predefinedFormValues.values.content;
            } else {
                this.validationFormGroup.patchValue(predefinedFormValues.values);
            }
        }
    }

    /**
     * User submitted the form
     *
     * @param values - form values
     */
    onSubmitCommunicationZoneData(values) {
        if (values) {
            if (this.type === CommunicationZoneFormEnum.order) {
                this.communicationZoneMutation.insertOrderCommunicationZone({
                    content: this.contentPrefix + values.content,
                    contactPerson: values.contactPerson,
                    orderId: this.id
                });
            } else {
                this.communicationZoneMutation.insertCommunicationZone({
                    title: this.title ? this.title : values.title,
                    content: this.contentPrefix + values.content,
                    contactPerson: values.contactPerson,
                    referenceType: this.referenceType ? this.referenceType : null,
                    referenceId: this.id ? this.id : null
                });
            }
            void this.contactPersonService.set(values.contactPerson);
            void this.modalService.dismiss();
        }
    }
}
