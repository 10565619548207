export enum UserAccessRightsEnum {
    PHARMACY_OWNER = 'pharmacy-owner',
    PHARMACY_MEMBER = 'pharmacy-member',
    STATISTICS = 'acl-statistics',
    INVOICES = 'acl-e-invoice',
    ARCHIVE = 'acl-archive',
    USER_ADMINISTRATION = 'acl-user-administration',
    SANAVENDI = 'acl-sanavendi',
}

// This enum describes the modul names that are returned by SSO
export enum UserAccessModulesEnum {
    SCONNECT = 'sconnect',
    CHAT = 'chat',
    SHOP = 'shop',
    SACADEMY = 'sacademy',
    SANAVENDI = 'sanavendi',
}
