import { gql } from '@apollo/client/core';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';

import { convertFromLocalTimeToUTC, endOf } from '../../../formatting/date.formatting';
import { ReturnsSortInterface } from '../../../interfaces/sorting.interface';
import { ReturnsDetailInterface, ReturnsInterface, ReturnsFiltersInterface } from '../../../interfaces/returns.interface';
import { QueryFetchPolicy } from '../../../enums/api.enum';
import { SortDirectionEnum } from '../../../enums/sort-direction.enum';
import { defaultReturnsSorting } from '../../../config/sorting.config';
import { GraphQLLimits } from '../../../config/graphql-limits.config';
import { getDateFromConfig, getDateRangesWithoutDates } from '../../../config/date-range.config';
import { ReturnsStatusAllConfig, ReturnsTypeAllConfig } from '../../../config/returns.config';
import { NotesInterface } from '../../../interfaces/notes.interface';
import { AuthStorageKeyEnum } from '../../../enums/authStorageKey.enum';
import { QueryWrapper } from '../query.wrapper';
import { DataChangedStateVar } from '../../locals/dataChangeState.var';
import { FetchPolicyKeys as FPK } from '../../../enums/fetch-policy-keys.enum';
import{ DataChangedKeys as DCK } from '../../../enums/data-changed-keys.enum';
import { TableFieldsEnum } from '../../../enums/table.enum';

export const GetReturns = (queryName) => gql`
    query ${queryName}($apiUser: String!, $offset: Int, $limit: Int, $order_by: [returns_order_by!], $where: returns_bool_exp) {
        pharmacyStore: pharmacyStoreReturns(where: {apiUser: {_eq: $apiUser}}, limit: 1) {
            returns(offset: $offset, limit: $limit, order_by: $order_by, where: $where)  {
                id
                productName
                packageSize
                pzn
                quantity
                status
                type
                price
                recTime
            }
        }
    }
`;

export const GetReturnById = (queryName) => gql`
    query ${queryName}($id: Int!) {
        returns_by_pk(id: $id) {
            id
            deliveryNoteDate
            deliveryNoteNumber
            productName
            packageSize
            dosageForm
            pzn
            producer
            quantity
            status
            type
            price
            recTime
            rejection
            notes {
                note
            }
        }
    }
`;

export const GetReturnsCount = (queryName) => gql`
    query ${queryName}($where: returns_bool_exp) {
        returns_aggregate(where: $where) {
            aggregate {
                count
            }
        }
    }
`;

export const GetReturnsPZNCollection = (queryName) => gql`
    query ${queryName}($apiUser: String!, $limit: Int, $where: returns_bool_exp!) {
        pharmacyStore: pharmacyStoreReturns(where: {apiUser: {_eq: $apiUser}}, limit: 1) {
            returns(limit: $limit, distinct_on:[pzn], order_by: [{pzn: asc}],
                where: $where) {
                pzn
            }
        }
    }
`;

export const GetReturnsProducerCollection = (queryName) => gql`
    query ${queryName}($apiUser: String!, $limit: Int, $where: returns_bool_exp!) {
        pharmacyStore: pharmacyStoreReturns(where: {apiUser: {_eq: $apiUser}}, limit: 1) {
            returns(limit: $limit, distinct_on:[producer], order_by: [{producer: asc}], where: $where) {
                producer
            }
        }
    }
`;

export const GetReturnsDeliveryNoteNumberCollection = (queryName) => gql`
    query ${queryName}($apiUser: String!, $limit: Int, $where: returns_bool_exp!) {
        pharmacyStore: pharmacyStoreReturns(where: {apiUser: {_eq: $apiUser}}, limit: 1) {
            returns(limit: $limit, distinct_on:[deliveryNoteNumber], order_by: [{deliveryNoteNumber: asc}],
                where: $where) {
                deliveryNoteNumber
            }
        }
    }
`;

const GetReturnsNotesByReturnsId = (queryName) => gql`
    query ${queryName}($ids: [Int!], $limit: Int) {
        note(limit: $limit, where: {returnsId: {_in: $ids}}) {
            id
            returnsId
            note
            created_at
            updated_at
        }
    }`;

export const AllReturnQueries = [
    GetReturnById('test'),
    GetReturnsCount('test'),
    GetReturnsPZNCollection('test'),
    GetReturnsProducerCollection('test'),
    GetReturnsDeliveryNoteNumberCollection('test'),
    GetReturnsNotesByReturnsId('test')
];

@Injectable()
export class ReturnsQueries extends QueryWrapper {

    fetchPolicies = {
        [FPK.getReturns]: QueryFetchPolicy.NETWORK_ONLY,
        [FPK.getReturnById]: QueryFetchPolicy.NETWORK_ONLY,
        [FPK.getReturnsCount]: QueryFetchPolicy.NETWORK_ONLY,
        [FPK.getReturnsPZNCollection]: QueryFetchPolicy.NETWORK_ONLY,
        [FPK.getReturnsProducerCollection]: QueryFetchPolicy.NETWORK_ONLY,
        [FPK.getDeliveryNoteNumberCollection]: QueryFetchPolicy.NETWORK_ONLY,
        [FPK.getReturnsNotesByReturnsIds]: QueryFetchPolicy.NETWORK_ONLY,
        [FPK.getFiltersReturns]: QueryFetchPolicy.NETWORK_ONLY,
        [FPK.getSorting]: QueryFetchPolicy.NETWORK_ONLY,
    };

    constructor(
        private apollo: Apollo,
        private dataChangedVar: DataChangedStateVar
    ) {
        super(apollo, dataChangedVar, {
            [DCK.returnsChanged]: [
                FPK.getReturns,
                FPK.getReturnById,
                FPK.getReturnsCount,
                FPK.getReturnsPZNCollection,
                FPK.getReturnsProducerCollection,
                FPK.getDeliveryNoteNumberCollection
            ],
            [DCK.noteChanged]: [
                FPK.getReturnsNotesByReturnsIds
            ],
            [DCK.sortingChanged]: [
                FPK.getSorting
            ],
            [DCK.returnsFilterChanged]: [
                FPK.getFiltersReturns
            ]
        });
    }

    /**
     * Get list of orders depending on filters, offset and limit.
     * Results can be ordered.
     *
     * @param filters - Filters for returns
     * @param offset - Offset for pagination
     * @param limit - Maximum number of items to return
     * @param orderBy - Order by field and direction
     */
    public getReturns(
        filters: ReturnsFiltersInterface,
        offset= 0,
        limit= 0,
        orderBy: ReturnsSortInterface = {
            field: defaultReturnsSorting.fieldName,
            direction: defaultReturnsSorting.sortDirection
        }
    ): Observable<ReturnsInterface[]> {
        const variables = {};
        variables['apiUser'] = localStorage.getItem(AuthStorageKeyEnum.activePharmacy);
        const orderByObj = {};
        if (!Object.values(TableFieldsEnum).includes(orderBy['field'])) {
            orderBy.field = defaultReturnsSorting.fieldName;
            orderBy.direction = defaultReturnsSorting.sortDirection;
        }
        switch (orderBy['field']) {
            case TableFieldsEnum.notes:
                orderByObj['notes_aggregate'] = {max: {id:
                            orderBy.direction === SortDirectionEnum.asc ? SortDirectionEnum.descNullsLast : SortDirectionEnum.ascNullsFirst
                    }};
                break;
            default:
                orderByObj[orderBy['field']] = orderBy.direction;
                break;
        }
        variables['order_by'] = [orderByObj, {id: SortDirectionEnum.asc}];

        if(limit > GraphQLLimits.returns) {
            limit = GraphQLLimits.returns;
            console.error('Limit above maximum!');
        }

        if (limit > 0) {
            variables['limit'] = limit;
        }

        if (offset > 0) {
            variables['offset'] = offset;
        }

        if (filters) {
            variables['where'] = this.createFiltersWhere(filters);
        }


        const fetchPolicyKey = FPK.getReturns;
        return this.apollo.watchQuery({
            query: GetReturns(fetchPolicyKey),
            fetchPolicy: this.getFetchPolicy(fetchPolicyKey),
            variables
        })
            .valueChanges
            .pipe(map(d => d?.data && d?.data['pharmacyStore'] && d?.data['pharmacyStore'][0] &&
                d?.data['pharmacyStore'][0]['returns']))
            .pipe(map((d) => {if (d) this.updateFetchPolicy(fetchPolicyKey); return d;})) as Observable<ReturnsInterface[]>;
    }

    /**
     * Return a single return by id
     *
     * @param id - Id of the return
     */
    public getReturnById(id: number): Observable<ReturnsDetailInterface> {
        const fetchPolicyKey = FPK.getReturnById;
        return this.apollo.watchQuery({
            query: GetReturnById(fetchPolicyKey),
            variables: {id},
            fetchPolicy: this.getFetchPolicy(fetchPolicyKey)
        })
            .valueChanges
            .pipe(map(d => d?.data && d?.data['returns_by_pk']))
            .pipe(map((d) => {if (d) this.updateFetchPolicy(fetchPolicyKey); return d;})) as Observable<ReturnsDetailInterface>;
    }

    /**
     * Return amount of available returns
     */
    public getReturnsCount(
        filters: ReturnsFiltersInterface,
        fetchPolicy: QueryFetchPolicy = null): Observable<number> {
        const variables = {};
        if(filters) {
            variables['where'] = this.createFiltersWhere(filters);
        }

        const fetchPolicyKey = FPK.getReturnsCount;
        return this.apollo.watchQuery({
            query: GetReturnsCount(fetchPolicyKey),
            variables,
            fetchPolicy: fetchPolicy ? fetchPolicy : this.getFetchPolicy(fetchPolicyKey)
        })
            .valueChanges
            .pipe(map(d => d?.data && d?.data['returns_aggregate']
              && d?.data['returns_aggregate']['aggregate']
              && d?.data['returns_aggregate']['aggregate']['count']))
            .pipe(map((d) => {if (d && !fetchPolicy) this.updateFetchPolicy(fetchPolicyKey); return d;})) as Observable<number>;
    }

    /**
     * Return an array of pzn
     */
    public getReturnsPZNCollection(searchString: string = ''): Observable<string[]> {
        // Fill the string with underscores and replace the 0s with empty string
        // for correct pzn search
        const where = this.quantityGreaterThanZeroFilter();
        if (searchString?.length > 0) {
            const searchStringFormatted = searchString.trim().replace(/^0+/g, '');
            where['_and'].push({pzn: {_ilike: searchStringFormatted + '%'}});
        } else {
            where['_and'].push({pzn: {_ilike:'%'}});
        }
        where['_and'].push({pzn: {_is_null: false}});


        const fetchPolicyKey = FPK.getReturnsPZNCollection;
        return this.apollo.watchQuery({
            query: GetReturnsPZNCollection(fetchPolicyKey),
            variables: {limit: GraphQLLimits.order, where, apiUser: localStorage.getItem(AuthStorageKeyEnum.activePharmacy)},
            fetchPolicy: this.getFetchPolicy(fetchPolicyKey)
        })
            .valueChanges
            .pipe(map(d => d?.data && d?.data['pharmacyStore'] && d?.data['pharmacyStore'][0] &&
                d?.data['pharmacyStore'][0]['returns']))
            .pipe(map((returns: [{pzn: string;}]) =>
                (!returns ? [] : returns.map(item => item.pzn.padStart(8, '0')))
            ))
            .pipe(map((d) => {if (d) this.updateFetchPolicy(fetchPolicyKey); return d;})) as Observable<string[]>;
    }

    /**
     * Return an array of producers
     */
    public getReturnsProducerCollection(searchString: string = ''): Observable<string[]> {
        const where = this.quantityGreaterThanZeroFilter();
        where['_and'].push({_and:[{producer: {_is_null: false}}, {producer: {_ilike: `%${searchString ? searchString.trim() : ''}%`}}]});

        const fetchPolicyKey = FPK.getReturnsProducerCollection;
        const producers : Observable<[{producer: string;}]> = this.apollo.watchQuery({
            query: GetReturnsProducerCollection(fetchPolicyKey),
            variables: {limit: GraphQLLimits.order, where, apiUser: localStorage.getItem(AuthStorageKeyEnum.activePharmacy)},
            fetchPolicy: this.getFetchPolicy(fetchPolicyKey)
        })
            .valueChanges
            .pipe(map(d => d?.data && d?.data['pharmacyStore'] && d?.data['pharmacyStore'][0] &&
                d?.data['pharmacyStore'][0]['returns']))
            .pipe(map((d) => {if (d) this.updateFetchPolicy(fetchPolicyKey); return d;})) as Observable<[{producer: string;}]>;
        return producers.pipe(map((returns: [{producer: string;}]) => (
            !returns ? [] : returns.map(item => item.producer)))
        ) as Observable<string[]>;
    }

    /**
     * Return an array of delivery note numbers
     */
    public getDeliveryNoteNumberCollection(searchString: string = ''): Observable<string[]> {
        const where = this.quantityGreaterThanZeroFilter();
        const search  = [];
        if (searchString && searchString.length > 0) {
            const searchStringFormatted = searchString.trim().replace('-', '').replace(/^0+/g, '');
            search.push({deliveryNoteNumber: {_ilike: searchStringFormatted + '%'}});
            if (searchString.length <= 10) {
                search.push({deliveryNoteNumber: {_ilike: '0' + searchStringFormatted + '%'}});
                search.push({deliveryNoteNumber: {_ilike: '00' + searchStringFormatted + '%'}});
            }
        } else {
            search.push({deliveryNoteNumber: {_ilike:'%'}});
        }
        where['_and'].push({_or: search});
        where['_and'].push({deliveryNoteNumber: {_is_null: false}});


        const fetchPolicyKey = FPK.getDeliveryNoteNumberCollection;
        return this.apollo.watchQuery({
            query: GetReturnsDeliveryNoteNumberCollection(fetchPolicyKey),
            variables: {limit: GraphQLLimits.order, where, apiUser: localStorage.getItem(AuthStorageKeyEnum.activePharmacy)},
            fetchPolicy: this.getFetchPolicy(fetchPolicyKey)
        })
            .valueChanges
            .pipe(map(d => d?.data && d?.data['pharmacyStore'] && d?.data['pharmacyStore'][0] &&
                d?.data['pharmacyStore'][0]['returns']))
            .pipe(map((returns: [{deliveryNoteNumber: string;}]) =>
                (!returns ? [] : returns.map(item => item.deliveryNoteNumber))
            ))
            .pipe(map((d) => {if (d) this.updateFetchPolicy(fetchPolicyKey); return d;})) as Observable<string[]>;
    }

    /**
     * Return a note of the given order id
     */
    public getReturnsNotesByReturnsIds(ids: number[]): Observable<NotesInterface[]> {
        const fetchPolicyKey = FPK.getReturnsNotesByReturnsIds;
        return this.apollo.watchQuery({
            query: GetReturnsNotesByReturnsId(fetchPolicyKey),
            variables: {ids, limit: GraphQLLimits.note},
            fetchPolicy: this.getFetchPolicy(fetchPolicyKey)
        })
            .valueChanges
            .pipe(map(d => d?.data && d?.data['note']))
            .pipe(map((d) => {if (d) this.updateFetchPolicy(fetchPolicyKey); return d;})) as Observable<NotesInterface[]>;
    }


    // ############# Helper Functions ################################################################################################################
    private quantityGreaterThanZeroFilter = () => {
        // only returns with a quantity > 0 should be shown
        const where = {_and: []};
        where['_and'].push({quantity: {_gt: 0}});
        return where;
    }

    private createFiltersWhere = (filters: ReturnsFiltersInterface) => {
        const where = this.quantityGreaterThanZeroFilter();

        const queryFilters = [];

        if (filters.search) {
            const searchStringFormatted = filters.search.replace('-', '').replace(/^0+/g, '');
            const searchNumber = parseInt(searchStringFormatted, 10);

            where['_and'].push({
                _or: [
                    {productName: {_ilike: `%${filters.search}%`}},
                    {producer: {_ilike: `%${filters.search}%`}},
                ]
            });

            if (!isNaN(searchNumber)) {
                where['_and'][1]['_or'].push(
                    {deliveryNoteNumber: {_ilike: searchStringFormatted + '%'}},
                    {pzn: {_ilike: `%${searchNumber}%`}}
                );
                if (searchStringFormatted.length <= 10) {
                    where['_and'][1]['_or'].push(
                        {deliveryNoteNumber: {_ilike: '0' + searchStringFormatted + '%'}},
                        {deliveryNoteNumber: {_ilike: '00' + searchStringFormatted + '%'}}
                    );
                }
            }
        }

        if (filters.status && filters.status.toUpperCase() !== ReturnsStatusAllConfig.id.toUpperCase()) {
            queryFilters.push({status: {_in: filters.status}});
        }

        if (filters.type && filters.type.toUpperCase() !== ReturnsTypeAllConfig.id.toUpperCase()) {
            queryFilters.push({type: {_eq: filters.type}});
        }

        if (filters.pzn) {
            queryFilters.push({pzn: {_ilike: parseInt(filters.pzn, 10).toString()}});
        }
        if (filters.producer) {
            queryFilters.push({producer: {_eq: filters.producer}});
        }
        if (filters.deliveryNoteNumber) {
            queryFilters.push({deliveryNoteNumber: {_eq: filters.deliveryNoteNumber}});
        }

        if (filters.dateOption) {
            if (!getDateRangesWithoutDates(filters.dateOption)
                && filters.dateFrom && filters.dateTo) {
                // necessary to convert date to utc cause recTime is a datetime field
                queryFilters.push({recTime: {_gte: convertFromLocalTimeToUTC(filters.dateFrom)}});
                queryFilters.push({recTime: {_lte: convertFromLocalTimeToUTC(endOf('day', true, filters.dateTo))}});
            } else {
                const fromDate = getDateFromConfig(filters.dateOption, true);
                const toDate = getDateFromConfig(filters.dateOption, false);
                if(fromDate && toDate) {
                    queryFilters.push({recTime: {_gte: fromDate}});
                    queryFilters.push({recTime: {_lte: toDate}});
                }
            }
        }

        if (filters.deliveryNoteDateOption) {
            if (!getDateRangesWithoutDates(filters.deliveryNoteDateOption)
                && filters.deliveryNoteDateFrom && filters.deliveryNoteDateTo) {
                // necessary to convert date to utc cause recTime is a datetime field
                queryFilters.push({deliveryNoteDate: {_gte: filters.deliveryNoteDateFrom}});
                queryFilters.push({deliveryNoteDate: {_lte: endOf('day', true, filters.deliveryNoteDateTo)}});
            }
        }

        if (queryFilters.length > 0) {
            where['_and'].push({
                _and: queryFilters
            });
        }
        return where;
    };
}
